@import '@grow-frontend/ui-kit/src/index';
@import './modules/copy-editor/index';
@import './DayPicker';

/* stylelint-disable selector-no-vendor-prefix, property-no-vendor-prefix, no-duplicate-selectors */
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font-size: inherit;
}

:root {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  cursor: default;
  font-size: 0.625rem;
  line-height: 1.5;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Helvetica Neue', Helvetica, Ubuntu, Roboto, Noto,
    'Segoe UI', Arial, sans-serif;
  font-size: 1.6rem;
  margin: 0;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.25;
}

button,
a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

[hidden] {
  display: none;
}

[unselectable] {
  user-select: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background-color: transparent;
  width: 100%;

  &::-ms-expand {
    display: none;
  }
}

input,
textarea,
select,
button {
  font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Helvetica Neue', Helvetica, Ubuntu, Roboto, Noto,
    'Segoe UI', Arial, sans-serif;
}

.underline {
  text-decoration: underline;
}

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
}

code,
kbd,
pre,
samp {
  font-family: monospace;
}

fieldset,
button {
  appearance: none;
  border: none;
  outline: none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

audio:not([controls]) {
  display: none;
}

details {
  display: block;
}

input {
  &:focus,
  &:active {
    outline: none;
  }

  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #6b778b;
  }

  &[type='number'] {
    width: auto;
  }

  &[type='search'] {
    -webkit-appearance: textfield;

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }
}

/**
  * transition styles for all reactcsstransitiongroup
  * components we are using within the application
  */

/* ========================
  * FadeIn
  ========================= */
.FadeIn-appear {
  opacity: 0.01;
}

.FadeIn-appear.FadeIn-appear-active {
  opacity: 1;
  transition: opacity 366ms cubic-bezier(0.39, 0.575, 0.565, 1);
}

.FadeIn {
  &-enter {
    opacity: 0.01;
  }

  &-enter-active {
    opacity: 1;
    transition: all 366ms ease-out;
  }

  &-leave {
    opacity: 1;
  }

  &-leave-active {
    opacity: 0;
    transition: all 366ms ease-out;
  }
}

/* ========================
  * FadeInFast
  ========================= */
.FadeInFast-appear {
  opacity: 0.01;
}

.FadeInFast-appear.FadeInFast-appear-active {
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
}

.FadeInFast {
  &-enter {
    transform-origin: right top;
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: all 280ms ease;
  }

  &-leave {
    opacity: 1;
    transform: none;
  }

  &-leave-active {
    opacity: 0;
    transform: translateY(5%);
    transition: all 200ms ease;
  }
}

.MemberNoteComposer {
  position: fixed;
  z-index: 10;
  bottom: 2.4rem;
  right: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &-enter {
    transform: scale(0.8);
  }

  &-enter-active {
    transform: none;
    transition: all 250ms ease-out;
  }

  &-leave {
    transform: none;
  }

  &-leave-active {
    transform: scale(0.7);
    opacity: 0;
    transition: all 150ms ease-out;
  }
}

.Modal {
  &-appear,
  &-enter {
    opacity: 0.01;
  }

  &-appear-active,
  &-enter-active {
    opacity: 1;
    transform: none;
    transition: all 280ms cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  &-leave {
    opacity: 1;
  }

  &-leave-active {
    opacity: 0;
    transform: translateY(6%);
    transition: all 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
  }
}

.Notification {
  &-appear,
  &-enter {
    opacity: 0;
    transform: translateY(-20%);
  }

  &-appear-active,
  &-enter-active {
    opacity: 1;
    transform: none;
    transition: all 300ms ease-out;
  }

  &-leave {
    opacity: 1;
  }

  &-leave-active {
    opacity: 0;
    transition: all 300ms ease-out;
  }
}
