$text-color: #102341;
$disabled: rgb(188 191 193 / 100%);

.ace-tm {
  color: $text-color;
}

.ace-disabled {
  color: $disabled;
}

.ace-tm .ace_variable {
  color: $text-color;
  font-weight: 600;
}

.ace-disabled .ace_variable {
  color: $disabled;
}

.ace-tm .ace_string {
  color: $text-color;
}

.ace-disabled .ace_string {
  color: $disabled;
}

.ace_paren {
  color: $text-color;
}

.ace-disabled .ace_paren {
  color: $disabled;
}

.ace-tm .ace_gutter {
  color: rgb(188 191 193 / 100%);
  background-color: white;
}

.ace-tm .ace_constant.ace_language {
  color: #448aff;
}

.ace-tm .ace_constant.ace_numeric {
  color: #448aff;
}

.ace-disabled .ace_constant.ace_language {
  color: $disabled;
}

.ace-disabled .ace_constant.ace_numeric {
  color: $disabled;
}

.ace-tm .ace_fold {
  background-color: #448aff;
}

.ace-marker {
  position: absolute;
  background-color: rgb(128 195 67 / 20%);
}
